import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FAQsContent from '../../components/FAQs/FAQs'
import faqs from '../../components/FAQs/faqs-list'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { FAQS as FAQS_LINK } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'FAQs',
    url: FAQS_LINK,
  },
]

const FAQs: React.FC = () => (
  <Layout
    showBreadcrumbs
    breadcrumbs={breadcrumbs}
    desktopBreadcrumbColor="black"
    pokerOneLink={pokerOneLinksStaticPage}
    rummyOneLink={rummyOneLinksStaticPage}
  >
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Frequently Asked Questions: Read FAQs on Gaming & Mega App"
      description="Read frequently asked questions on real money gaming and much more. Queries are segmented across six broad categories to answer all questions. Click to read more!"
      organizationSchema={generateOrgSchema()}
      websiteSchema={generateWebsiteSchema()}
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      faqSchema={generateFaqSchema(faqs)}
    />
    <FAQsContent faqs={faqs} />
  </Layout>
)

export default FAQs
